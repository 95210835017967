import React from "react";
import {LicPackageLevel, Roles} from "../constans/roles";
import NoRoleComponent from "./NoRoleComponent";
import {connect} from "react-redux";
import {get, isEmpty} from "lodash";
import {getAllChildrenForLocationID} from "../utils/BuildingUtils";
import InfoBox from "./basics/info-box/InfoBox";
import i18n from "../i18n";

/**
 * HOC do wrapowania komponentu (tak jak reduxForm albo connect) do sprawdzania czy użytkownik
 * ma potrzebne role do wyświetlenia komponentu
 * @param props {object|function}               obiekt z danymi, moze byc funkcja, ktora zwraca obiekt
 *                                              (przydatne przy wyciaganiu DevID, poniewaz parametrem sa propsy komponentu)
 *
 * @property props.roles {array}                lista ról minimalnych dla widoku
 *
 * @property props.devID {string}               DevID urządzenia dla roli
 *
 * @property props.showComponent {boolean}      określenie czy ma pokazać komponent, jeżeli ktoś nie ma
 *                                              uprawnień do wejścia
 *
 * @property props.clientPackage                obiekt z minimalnymi parametrami wejścia na widok, każdy klucz
 *                                              przyjmuje wartość "BASIC" lub "EXTENDED"
 *
 * @property props.clientPackage.counters       klucz odpowiedzialny za liczniki
 * @property props.clientPackage.siloses        klucz odpowiedzialny za silosy
 * @property props.clientPackage.dispensers     klucz odpowiedzialny za dozowniki
 * @property props.clientPackage.climate        klucz odpowiedzialny za klimaty
 * @property props.clientPackage.cages          klucz odpowiedzialny za klatki selekcyjne
 * @property props.clientPackage.smallCages     klucz odpowiedzialny za małą klatkę warchlaków
 * @property props.clientPackage.alarms         klucz odpowiedzialny za alarmy
 * @property props.clientPackage.chains         klucz odpowiedzialny za paszociągi
 * @property props.clientPackage.managment      klucz odpowiedzialny za zarządzanie
 * @property props.clientPackage.farm           klucz odpowiedzialny za część hodowlaną
 * @return {function(*): WithRoles}             komponent
 */
const withRoles = props => WrappedComponent => {

    class WithRoles extends React.Component {

        state = {
            show: false
        };

        p = this.getProps();

        componentDidMount() {
            this.setState({
                show: this.checkIfCanBeDisplayed()
            })
        }

        // pobranie propsow
        getProps() {
            if (typeof props === "function") return props(this.props);
            return props;
        }

        /**
         * funkcja sprawdzająca czy użytkownik posiada odpowiedni typ pakietu
         * @param requestedLevel - "BASIC", "EXTENDED"
         * @param hasBasicLevel {boolean}
         * @param hasExtendedLevel {boolean}
         * @returns {boolean}
         */
        checkIfLicPackageIsValid(requestedLevel, hasBasicLevel, hasExtendedLevel) {
            if (requestedLevel === LicPackageLevel.BASIC && hasBasicLevel) return true;
            return !!(requestedLevel === LicPackageLevel.EXTENDED && hasBasicLevel && hasExtendedLevel);
        }

        checkIfCanBeDisplayed() {
            const {roles = [], clientPackage, devPlcmnts} = this.p;
            const {farm, user} = this.props;
            //jesli uzytkownik jest ma tylko suba to zwracamy tylko widok dla subow albo nic
            if (user.Roles.find(r => r.Role === Roles._SUBSCRIPTION)) {
                return roles.includes(Roles._SUBSCRIPTION);
            }
            let isService = user.Roles.find(r => r.Role === Roles._SERVICE);
            if (isService) {
                if (farm) {
                    let isServiceOfFarm = !!isService.LocalRights.find(item => item.FarmID === farm);
                    if (isServiceOfFarm) return true;
                } else {
                    return true;
                }
            }
            // sprawdzanie pakietów licencyjnych
            const userPackage = get(user, `Packages[${farm}]`, {});
            if (clientPackage) {
                if (isEmpty(userPackage)) return false;
                if (clientPackage.administration && !this.checkIfLicPackageIsValid(clientPackage.administration, userPackage.admBasic, userPackage.admExt)) return false;
                if (clientPackage.alarms && !this.checkIfLicPackageIsValid(clientPackage.alarms, userPackage.alBasic, userPackage.alExt)) return false;
                if (clientPackage.cages && !this.checkIfLicPackageIsValid(clientPackage.cages, userPackage.cgBasic, userPackage.cgExt)) return false;
                if (clientPackage.chains && !this.checkIfLicPackageIsValid(clientPackage.chains, userPackage.chBasic, userPackage.chExt)) return false;
                if (clientPackage.climates && !this.checkIfLicPackageIsValid(clientPackage.climates, userPackage.clBasic, userPackage.clExt)) return false;
                if (clientPackage.counters && !this.checkIfLicPackageIsValid(clientPackage.counters, userPackage.cntBasic, userPackage.cntExt)) return false;
                if (clientPackage.dispensers5G && !this.checkIfLicPackageIsValid(clientPackage.dispensers5G, userPackage.di5GBasic, userPackage.di5GExt)) return false;
                if (clientPackage.dispensers && !this.checkIfLicPackageIsValid(clientPackage.dispensers, userPackage.diBasic, userPackage.diExt)) return false;
                if (clientPackage.porkers && !this.checkIfLicPackageIsValid(clientPackage.porkers, userPackage.porkerBasic, userPackage.porkerExt)) return false;
                if (clientPackage.smallCages && !this.checkIfLicPackageIsValid(clientPackage.smallCages, userPackage.scBasic, userPackage.scExt)) return false;
                if (clientPackage.siloses && !this.checkIfLicPackageIsValid(clientPackage.siloses, userPackage.slBasic, userPackage.slExt)) return false;
                if (clientPackage.managment && !this.checkIfLicPackageIsValid(clientPackage.managment, userPackage.mngBasic, userPackage.mngExt)) return false;
            }
            // jeżeli użytkownik ma uprawnienia OWNER zwraca true
            let isOwner = user.Roles.find(r => r.Role === Roles.OWNER);
            if (isOwner) {
                if (farm) {
                    let isOwnerOfFarm = !!isOwner.LocalRights.find(item => item.FarmID === farm);
                    if (isOwnerOfFarm) return true;
                } else {
                    return true;
                }
            }
            for (let role of roles) {
                // wyszukanie roli w obiekcie użytkownika
                let hasRole = get(user, "Roles", []).find((r) => r.Role === role);
                // jeżeli użytkownik nie ma roli zwraca false
                if (!hasRole) return false;
                // sprawdzenie czy użytkownik ma prawa do aktualnej farmy, sprawdza czy jest farma, aby móc pokazać farmChoosera
                if (farm) {
                    let hasRightsToFarm = hasRole.LocalRights.find(item => item.FarmID === farm);
                    console.log("hasRightsToFarm", hasRightsToFarm);
                    if (!hasRightsToFarm) return false;
                    // jesli przekazano devPlcmnts jako arraya walidujemy pod katem posiadanych rol
                    if (Array.isArray(devPlcmnts) && hasRightsToFarm) {
                        // jesli jest ono puste to tylko serwis lub owner moze przejsc na widok
                        if (isEmpty(devPlcmnts)) return false;
                        else {
                            // device config nie ma plcmts wiec sprawdzamy czy zostala przekazana jeszcze inna role z devicami
                            if (role !== Roles._DEVICE_CONFIG && role.startsWith("_DEVICE")) {
                                const rolePlmcnts = get(hasRightsToFarm, "Plcmnts", []);
                                // jesli user ma gwiazdke to przepuszczamy
                                if (rolePlmcnts[0] === "*") {
                                    return true;
                                } else {
                                    // jesli ma jakies wspolne plcmnty to przepuszczamy, w innym wypadku trzeba sprawdzić czy przypadkiem plcmtn device'a
                                    // nie zawiera sie w tym co ma user
                                    let commonPlcmnts = get(hasRightsToFarm, "Plcmnts", []).filter((p) => devPlcmnts.includes(p));
                                    if (!isEmpty(commonPlcmnts)) return true;
                                    else {
                                        for (let i = 0; i < rolePlmcnts.length; i++) {
                                            let childrenPlcmnts = getAllChildrenForLocationID(rolePlmcnts[i]);
                                            let isChildren = childrenPlcmnts.filter((childID) => devPlcmnts.includes(childID.PlcmntID));
                                            if (!isEmpty(isChildren)) return true;
                                        }
                                        return false;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            return true;
        }

        render() {
            const {show} = this.state;
            if (!show) {
                // jezeli okreslono wyswietlanie komponentu z brakiem uprawnien
                if (this.p.showComponent) return <NoRoleComponent/>;
                if (this.p.showInfo) return <InfoBox boxColor={"warning"}>{i18n.t("withRoles.noAccessWarningMessage")}</InfoBox>;
                else return null;
            }
            return <WrappedComponent {...this.props}/>;
        }
    }

    WithRoles.displayName = `WithRoles(${WrappedComponent.displayName || WrappedComponent.name || "Component"})`;

    return connect(
        state => ({
            farm: state.location.farm,
            user: state.user.user
        })
    )(WithRoles);

};

export default withRoles;

